import {
  AgColDefsI,
  AgGridI,
} from "src/app/shared/components/ag-grid/ag-grid.model";
import { AgSwitchComponent } from "src/app/shared/components/ag-grid/ag-switch/ag-switch.component";
import { MailingListRendererComponent } from "src/app/shared/components/ag-grid/mailing-list-renderer/mailing-list-renderer.component";
import { SvgRendererComponent } from "src/app/shared/components/ag-grid/svg-renderer/svg-renderer.component";
export class ListManager {}

export enum GridName {
  LISTMANAGER = "list-manager",
}
const MailingListColumn: AgColDefsI = {
  headerName: "Mailing List",
  field: "name",
  sortable: true,
  headerCheckboxSelection: true,
  lockPosition: "left",
  suppressMovable: false,
  lockPinned: true,
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  cellStyle: {
    paddingLeft: "1.2em",
  },
  tooltipField: "name",
  width: 350,
  cellRenderer: MailingListRendererComponent,
};
const NumberOfRecordsColumn: AgColDefsI = {
  headerName: "Number of records",
  field: "noOfRecords",
  sortable: true,
  suppressMovable: false,
  tooltipField: "noOfRecords",
  width: 100,
  cellStyle: { paddingLeft: "2.6rem", color: "#646E76" },
  cellClass: "padding-left-68px",
  cellRenderer: (params) =>
    params.value === "" || params.value === null
      ? "<p>&#8212;</p>"
      : params.value,
};
const CreatedBy: AgColDefsI = {
  headerName: "Created By",
  field: "createdBy",
  sortable: true,
  suppressMovable: false,
  tooltipField: "createdBy",
  width: 100,
  cellStyle: { paddingLeft: "2.6rem", color: "#646E76" },
  cellRenderer: (params) =>params.value === "" || params.value === null? "<p>&#8212;</p>": params.value,
};
const ActiveColumn: AgColDefsI = {
  headerName: "Active",
  field: "isActive",
  sortable: true,
  width: 118,
  cellClass: "d-flex justify-content-end",
  cellRenderer: AgSwitchComponent,
  resizable:false
};
const CompanyColumn: AgColDefsI = {
  headerName: "Company",
  field: "companyName",
  sortable: true,
  headerCheckboxSelection: true,
  lockPosition: "left",
  suppressMovable: false,
  lockPinned: true,
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  cellStyle: {
    paddingLeft: "1.2em",
  },
  tooltipField: "companyName",
  width: 200,
   cellRenderer: (params) => params.value === "" || params.value === null? "<p>&#8212;</p>": params.value,
};
const ContactFirstNameColumn: AgColDefsI = {
  headerName: "Contact First Name",
  field: "contactFirstName",
  sortable: true,
  lockPinned: true,
  width: 110,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const ContactLastNameColumn: AgColDefsI = {
  headerName: "Contact Last Name",
  field: "contactLastName",
  sortable: true,
  lockPinned: true,
  width: 110,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const EmailAddressColumn: AgColDefsI = {
  headerName: "Email Address",
  field: "emailAddress",
  sortable: true,
  lockPinned: true,
  width: 110,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const StatusColumn: AgColDefsI = {
  headerName: "Status",
  field: "status",
  sortable: true,
  lockPinned: true,
  width: 110,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const EditDeleteColumn: AgColDefsI = {
  headerName: "",
  field: "editDeleteColumn",
  width: 220,
  lockPinned: true,
  lockPosition:'right',
  cellStyle: {'color': '#646E76' },
  cellRenderer:SvgRendererComponent,
  cellRendererParams:{edit:'edit',delete:'delete'},
  resizable:false,
};

export const ColDefsListManagerInfo: AgColDefsI[] = [
  CompanyColumn,
  ContactFirstNameColumn,
  ContactLastNameColumn,
  EmailAddressColumn,
  StatusColumn,
  EditDeleteColumn,

];

export const ColDefsListManager: AgColDefsI[] = [
  MailingListColumn,
  NumberOfRecordsColumn,
  CreatedBy,
  ActiveColumn,
];
export const AgGridDataModelListManager: AgGridI = {
  colDefs: ColDefsListManager,
  rowSelection: "multiple",
  storageName: GridName.LISTMANAGER,
};
export const AgGridDataModelListManagerInfo: AgGridI = {
  colDefs: ColDefsListManagerInfo,
  rowSelection: "multiple",
 // storageName: GridName.LISTMANAGER,
};

