import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AgGridService } from 'src/app/shared/components/ag-grid/ag-grid.service';
import { Button, DeletePageType, PopUpTitles } from 'src/app/shared/model/enum';
import {AddEditMailingListComponent} from '../list-manager/Pop-up/add-edit-mailing-list/add-edit-mailing-list.component'
import { AgGridPaginationConfig, MailingListIds, PaginationBaseModel, TablePaginationModel } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { CommonModelPopUpComponent } from '../common-model-pop-up/common-model-pop-up.component';
import { ToastrService } from 'ngx-toastr';
import { ListManagerService } from '../list-manager/list-manager.service';
import { catchError } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {
  isButtonDisabled: boolean = false;
  enumButtonName          = Button;
  selectedData: any[] = [];
  @Input() isVisible: boolean = true;
  @Input() buttonName: string;
  paginationBaseModel     = new PaginationBaseModel();
  agGridPaginationConfig  = new AgGridPaginationConfig();
  enumPopUpTitles = PopUpTitles;
  mailingListIds : any;
  mailingListName: string[] = [];
  ResMsgFromMailingList:string;
  deletePageType = DeletePageType;
  tablePaginationModel = new TablePaginationModel();

  constructor( public agGridService: AgGridService,
    public modalService: NgbModal,
    public listManagerService :ListManagerService,
    public toaster :ToastrService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.agGridService.selectionChanged.subscribe((res) => {
      if (res) {
        this.selectedData = this.agGridService.getGridApi().getSelectedRows();
        this.mailingListName = this.selectedData.map((items) => {return  items.name; });

      }
  })
  this.initDatatable();

  }
  initDatatable() {
    this.paginationBaseModel={
      sortColumn: "",
      sortDirection: "",
      pageNumber: this.agGridPaginationConfig.currentPageNumber,
      pageSize: this.agGridPaginationConfig.pageSize,
      searchText: this.agGridPaginationConfig.searchText,
      getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
  }
    this.agGridService.paginationData.next(this.paginationBaseModel);
  }

  
  openEditModal(){
    if(this.selectedData.length != 0){
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "editMailinglist",
      };
      const modalRef = this.modalService.open(AddEditMailingListComponent, modalOption);
      modalRef.componentInstance.headingsNames = this.buttonName;
      if(this.buttonName == 'Add Company'){
        // this.companiesService.nextTab.next(false)
      }
    }    
  }

  openDeleteModal() {
    if(this.selectedData.length != 0){
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "deletePop",
      };
      const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption);
      modalRef.componentInstance.title = this.enumPopUpTitles.DELETE;
      modalRef.componentInstance.pageType = this.deletePageType.MAILINGLIST;
      modalRef.componentInstance.pagelistType = this.deletePageType.MAILINGLISTDETAILS;
      let companyName = this.mailingListName[1] != undefined ? ", " + this.mailingListName[1] : "";
      modalRef.componentInstance.fullName = `${this.mailingListName[0]}${companyName} ${this.mailingListName.length > 2? "&" + " " + (this.mailingListName.length - 2 + " ") + "others" : ""} `.trim();
      modalRef.result.then((result) => {
        if (result.confirm == true) {
          this.deleteMailingList();
          setTimeout(() => {
            if(result.title == 'Delete'){
              const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption);
              modalRef.componentInstance.title = this.enumPopUpTitles.DELETE;
              modalRef.componentInstance.deleteForAdmin = true;
              modalRef.componentInstance.ResMsgFromMailingList = this.ResMsgFromMailingList;
            }
            }, 1000);
        }
      });
    }
    }
  

deleteMailingList(){
  this.mailingListIds =  this.selectedData.map(item => item.id);
  if (this.mailingListIds.length > 0) {
    const idInstance = new MailingListIds(this.mailingListIds);
  this.listManagerService.deleteManagerList(idInstance).pipe(catchError((err) => this.handleError(err)))?.subscribe((res : Result) => {
    if (res.isSuccess == true){
      this.ResMsgFromMailingList = res.message;
      this.agGridService.getGridApi().updateRowData({ remove: this.selectedData });
    }else{    
      this.toaster.error(res.message)
    }
  });
}

}
searchTextFilter(searchText) {
  if(this.router.url.includes('/list-manager/list-manager-info')){
    const id = this.router.url.split('/').pop();
    this.tablePaginationModel.id = id;
    this.tablePaginationModel.searchText = searchText;
    this.agGridService.tablePaginationData.next(this.tablePaginationModel);
  }else{  this.agGridService.paginationData.next(this.paginationBaseModel);
  }
}


handleError(err: any): any {
  console.log(err);
}

}
