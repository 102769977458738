import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { catchError } from 'rxjs';
import { CommonModelPopUpComponent } from 'src/app/components/common-model-pop-up/common-model-pop-up.component';
import { AddEditMailingListComponent } from 'src/app/components/list-manager/Pop-up/add-edit-mailing-list/add-edit-mailing-list.component';
import { DeletePageType, PopUpTitles } from 'src/app/shared/model/enum';
import { AgGridService } from '../ag-grid.service';
import { ListManagerService } from 'src/app/components/list-manager/list-manager.service';
import { ToastrService } from 'ngx-toastr';
import { Result } from 'src/app/shared/model/response.model';

@Component({
  selector: 'app-svg-renderer',
  templateUrl: './svg-renderer.component.html',
  styleUrls: ['./svg-renderer.component.scss']
})
export class SvgRendererComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  enumPopUpTitles = PopUpTitles;
  ResMsgFromMailingList:string;
  deletePageType = DeletePageType;

  constructor(public modalService: NgbModal,
    public agGridService: AgGridService,
    public listManagerService :ListManagerService,
    public toaster :ToastrService,

  ) { }
  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  ngOnInit(): void {
  }
  openEditModal(params){
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    const modalRef = this.modalService.open(AddEditMailingListComponent, modalOption);
  }
  openDeleteModal(params) {
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "deletePop",
      };
      const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption);
      modalRef.componentInstance.title = this.enumPopUpTitles.DELETE;
      modalRef.componentInstance.pageType = this.deletePageType.MAILINGLISTDETAILS;
      modalRef.componentInstance.pagelistType = this.deletePageType.MAILINGLIST;
      modalRef.componentInstance.fullName = params.data.companyName;
      modalRef.result.then((result) => {
        if (result.confirm == true) {
          this.deleteMailingListDetails(params);
          setTimeout(() => {
            if(result.title == 'Delete'){
              const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption);
              modalRef.componentInstance.title = this.enumPopUpTitles.DELETE;
              modalRef.componentInstance.deleteForAdmin = true;
              modalRef.componentInstance.ResMsgFromMailingList = this.ResMsgFromMailingList;
            }
            }, 1000);
        }
      });
    }
  

    deleteMailingListDetails(params){
    const rowDataToRemove = [params.data];  
    this.listManagerService.deletemanagerListDetails({id:params.data.id}).pipe(catchError((err) => this.handleError(err)))?.subscribe((res : Result) => {
    if (res.isSuccess == true){
      this.ResMsgFromMailingList = res.message;
      this.agGridService.getGridApi().updateRowData({ remove: rowDataToRemove });
    }else{    
      this.toaster.error(res.message)
    }
  });
}

handleError(err: any): any {
  console.log(err);
}


}

