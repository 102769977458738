import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as feather from "feather-icons";
import { LayoutService } from "../../../services/layout.service";
import { NavService } from "../../../services/nav.service";
import { fadeInAnimation } from "../../../data/router-animation/router-animation";
import {CommonService} from "../../../services/common.service"
@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit {
  flexibleHeight: string;
  @ViewChild('footer') footer: ElementRef;

  constructor(private route: ActivatedRoute,public commonService:CommonService,
     public navServices: NavService, public layout: LayoutService) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  get layoutClass() {
    return "compact-wrapper";
  }
  ngAfterViewChecked(){
    this.commonService.headerHeight.subscribe(headerHeight=>{
    //  this.flexibleHeight = "min-height: calc(100vh - "+headerHeight+"px - "+ this.footer.nativeElement.offsetHeight+"px) !important;max-height: calc(100vh - "+headerHeight+"px - "+ this.footer.nativeElement.offsetHeight+"px) !important;height: calc(100vh - "+headerHeight+"px - "+ this.footer.nativeElement.offsetHeight+"px) !important;margin-top:"+ headerHeight+"px !important";
  const footerOffsetHeight = 30;
      this.flexibleHeight = `
        min-height: calc(100vh - ${headerHeight}px  - ${footerOffsetHeight}px) !important;
        max-height: calc(100vh - ${headerHeight}px - ${footerOffsetHeight}px) !important;
        height: calc(100vh - ${headerHeight}px  - ${footerOffsetHeight}px) !important;
        margin-top: ${headerHeight}px !important;
      `;
  })
  }

  ngOnInit() {
    this.insertUpdateSystemSettingData()
  }
   insertUpdateSystemSettingData() {
    const res = {
        "primaryMenus": {
            "backgroundColor": "#0067AC",
            "hoverAndActiveTextAndIconColor": "#4EABE1",
            "siteLogo": "SMDefaultLogo.png",
            "textAndIconColor": "#FFFFFF"
        },
        "secondaryMenus": {
            "accentLineColor": "#E92828",
            "backgroundColor": "#0067AC",
            "hoverAndActiveTextAndIconColor": "#4EABE1",
            "textAndIconColor": "#FFFFFF"
        },
        "favoriteDocks": {
            "backgroundColor": "#0067AC",
            "iconColor": "#FFFFFF"
        },
        "siteWides": {
            "hoverAndActiveColor": "#DE466D",
            "primaryAccentColor": "#0067AC",
            "secondaryAccentColor": "#4eabe1"
        }
    };

    // Extracting data from the response
    const primaryDe = res.primaryMenus;
    const secondaryDe = res.secondaryMenus;
    const dockDe = res.favoriteDocks;
    const siteDe = res.siteWides;

    // Primary menu styles
    const primary_bg_color = primaryDe.backgroundColor;
    const primary_text_icon_color = primaryDe.textAndIconColor;
    const primary_hover_active_color = primaryDe.hoverAndActiveTextAndIconColor;

    // Secondary menu styles
    const secondary_bg_color = secondaryDe.backgroundColor;
    const secondary_text_icon_color = secondaryDe.textAndIconColor;
    const secondary_hover_active_color = secondaryDe.hoverAndActiveTextAndIconColor;
    const accent_line_color = secondaryDe.accentLineColor;

    // Dock styles
    const dock_color = dockDe.backgroundColor;
    const icon_color = dockDe.iconColor;

    // Site-wide styles
    const sitewide_accent_color = siteDe.primaryAccentColor;
    const sitewide_secondary_color = siteDe.secondaryAccentColor;
    const sitewide_active_hover_color = siteDe.hoverAndActiveColor;

    // Applying styles to document root
    const root = document.documentElement.style;
    root.setProperty('--primary-bg-color', primary_bg_color);
    root.setProperty('--primary-text-color', primary_text_icon_color);
    root.setProperty('--primary-icon-color', primary_text_icon_color); // Is this intentional?
    root.setProperty('--primary-hover-active-color', primary_hover_active_color);

    root.setProperty('--secondary-bg-color', secondary_bg_color);
    root.setProperty('--secondary-text-icon-color', secondary_text_icon_color);
    root.setProperty('--secondary-hover-active', secondary_hover_active_color);
    root.setProperty('--secondary-accent-line-color', accent_line_color);

    root.setProperty('--dock-bg-color', dock_color);
    root.setProperty('--dock-icon-color', icon_color);

    root.setProperty('--sitewide-active-hover-color', sitewide_active_hover_color);
    root.setProperty('--sitewide_primary_accent_color', sitewide_accent_color);
    root.setProperty('--sitewide_secondary_color', sitewide_secondary_color);
}

}
