import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-mailing-list',
  templateUrl: './add-edit-mailing-list.component.html',
  styleUrls: ['./add-edit-mailing-list.component.scss']
})
export class AddEditMailingListComponent implements OnInit {
  @Input() isAddValue: boolean;
  constructor(public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
  }

  addEditValue(){

  }

  
 
}
