<app-custom-loader class="position-absolute top-50 start-50 translate-middle" style="z-index: 10;" [isLoaderLoading]="isLoading"></app-custom-loader>
<div class="grid-thumb w-100 h-100">
  <ag-grid-angular  #grid
      class="ag-theme-alpine w-100 h-100"
      [defaultColDef]="defaultColDef"
      [columnDefs]="dataModel.colDefs"
      [headerHeight]="32"
      [getRowHeight]="getRowHeight"
      [getRowStyle]="getRowStyle"
      [rowClassRules]="rowClassRules"
      [animateRows]="true"
      [suppressRowClickSelection]="true"
      [suppressRowTransform]="true"
      [stopEditingWhenCellsLoseFocus]="true"
      [singleClickEdit]="true"
      [suppressRowHoverHighlight]="true"
      [suppressDragLeaveHidesColumns]="true"
      [suppressColumnVirtualisation]="true"
      [rowData]="agGridPaginationConfig.rows"
      [rowSelection]="dataModel.rowSelection"
      (selectionChanged)="onSelectionChanged($event)"
      [components]="components"
      [suppressNoRowsOverlay]="agGridPaginationConfig.rows.length == 0 && isLoading" 
      (gridReady)="onGridReady($event)"
      [pagination]="true"
      [paginationPageSize]=" agGridPaginationConfig?.pageSize == 1
          ? agGridPaginationConfig?.totalRecords : agGridPaginationConfig?.pageSize"
      [suppressPaginationPanel]="true"
      (dragStopped)="onDragStopped($event)"
      (gridSizeChanged)="onGridSizeChanged($event)"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      (firstDataRendered)="onFirstDataRendered($event)"
      (rowClicked)="onRowClicked($event)"
      (columnResized)="onColumnResized($event)"
      (columnMoved)="columnMoved($event)"
      >
  </ag-grid-angular>
</div>
<div class="d-flex align-items-center h-50px" >
  <div class="pagination-control"
       [ngClass]="deviceWidth < 1200 ? 'pagination-start' : 'pagination-center'">
    <ngb-pagination
      #ngbPage
      [boundaryLinks]="false"
      [collectionSize]="agGridPaginationConfig?.totalRecords == 0
          ? 1: agGridPaginationConfig?.totalRecords"
      [pageSize]=" agGridPaginationConfig?.pageSize == 1
          ? agGridPaginationConfig?.totalRecords: agGridPaginationConfig?.pageSize"
      [page]="paginationBaseModel?.pageNumber"
      [maxSize]="5"
      (pageChange)="onPageChanged($event)"
    >
      <ng-template ngbPaginationPrevious>&#8810;</ng-template>
      <ng-template ngbPaginationNext>&#8811;</ng-template>
    </ngb-pagination>
  </div>
  <div class="d-flex align-self-center justify-content-end flex-grow-1" >
    <select
      class="b-r-5  text-primary-color px-1 hand font-11"
      [(ngModel)]="paginationBaseModel.pageSize"
      (ngModelChange)="onPageSizeChange($event)"
    >
      <option
        *ngFor="let option of agGridPaginationConfig.limitOptions"
        [ngValue]="option.value"
      >
        {{ option.key }}
      </option>
    </select>
    <label class="font-11 m-b-0 m-l-5 d-flex text-light-grey align-items-center"
      >Records Per Page</label
    >
  </div>
</div>


