<div class="container-fluid d-flex flex-column p-0">
  <div class="row mb-3 d-flex">
      <div class="d-flex justify-content-between align-items-center">
          <span class="col-2">
              <app-search-input [inputSearchPlaceholder]="'Search by list name…'" 
              class="w-330px px-3 d-flex align-items-end"
              [inputSerachValue]="paginationBaseModel.searchText"
              (inputSerachValueEmit)="searchTextFilter((paginationBaseModel.searchText = $event))"
              > </app-search-input>
          </span>
          <div class="d-flex" *ngIf="isVisible">
              <app-button
               class="px-2" 
               [buttonLable]="enumButtonName.CLONE"
               [inputReadonly]="selectedData?.length == 0"
               [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '"
              ></app-button>

              <app-button 
                class="px-2"
                [buttonLable]="enumButtonName.DELETE"
                [inputReadonly]="selectedData?.length == 0"
                [buttonClass]="selectedData?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '"
                (click)="openDeleteModal()"
                ></app-button>
              <app-button
               class="ps-2"
               [buttonLable]="enumButtonName.EDIT"
               [inputReadonly]="selectedData?.length == 0"
               [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '"
               (click)="openEditModal()"
               ></app-button>      
          </div>
      </div>
  </div>
</div>
