import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  passwordsMatch: boolean = true;
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Input () inputLabel :string = 'password';
  @Input () inputType:string;
  @Input () inputValue : string = "";
  @Input () inputDefaultShow:boolean=false
  @Input () inputReqValidation:boolean = false;
  showHide:boolean=true
  @Input() shouldPhoneFormat:boolean=false;
  @Input() shouldBogieFormat:boolean=false;  
  @Input() inputMaxlength:boolean=false;
  @Input() inputReadonly : boolean  = false;
  @Input() passwordFormat:boolean = false;
  @Input() isInputDisabled = false;
  @Input() isValid: boolean =false;
  @Input() isSvgVisible : boolean = false;
  @Input() labelStyle: { [key: string]: string } = {};
  
  inputPattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$"
  constructor() { }

  ngOnInit(): void {
  }

  passwordPattern(){
    if(this.inputLabel.includes('Password')){
      return this.inputPattern;
    }
  }

  clearInput(){
    if(!this.inputReadonly){
      this.inputValue = ''
    }
  }


}
