
<button
*ngIf="!button_2"
  class="btn btn-sm px-2 py-0 d-flex align-items-center justify-content-center" 
  [ngClass]="buttonClass"
  [disabled]="inputReadonly"
>
  <app-svg-icon [src]="imgUrl" [disabled]="inputReadonly"></app-svg-icon
  ><span class="my-auto" *ngIf="buttonLable" [disabled]="inputReadonly" [ngClass]="{ 'm-l-5': imgUrl}" >{{ buttonLable }}</span>
</button>

<button *ngIf="button_2" 
    [class]="buttonClass"
    [type]="buttonType"
    [ngClass]="ngClass"
    [disabled]="inputReadonly"
    [readonly]="inputReadonly">
    <span  [class]="spanClass"> {{buttonLable}}</span>
    <app-svg-icon [src]="buttonImageUrl"></app-svg-icon>
</button>
