<div
  class="d-flex w-100 h-100 align-items-center
  {{ params.displayName === enumDisplayName.ACTIVE ? 'justify-content-end' : '' }}
  {{ params.displayName === enumDisplayName.COMPANY ? 'p-l-20' : '' }}"
>
  <div>
    <img
      *ngIf="params.displayName !== enumDisplayName.MAILINGLIST && params.displayName !== enumDisplayName.COMPANY && params.displayName !== '' "
      class="m-r-10"
      style="cursor: grab"
      src="./assets/SVGIcons/Gridicon.svg"
      alt=""
      width="13"
      height="12"
    />
    <span
      class="m-r-10"
      [ngClass]="
        params.displayName == enumDisplayName.MAILINGLIST ? 'mailing-list' : ''
      "
    >
      {{ params.displayName }}
    </span>
  </div>

  <div class="d-flex flex-column sortIcon">
    <i
      *ngIf="params.enableSorting"
      (click)="onSortRequested('asc', $event)"
      [ngClass]="ascSort"
      class="fa fa-sort-up"
    ></i>
    <i
      *ngIf="params.enableSorting"
      (click)="onSortRequested('desc', $event)"
      [ngClass]="descSort"
      class="fa fa-sort-down"
    ></i>
  </div>
</div>
