<div class="media profile-media">
  <app-svg-icon [src]="'../../../../assets/SVGIcons/profile.svg'"></app-svg-icon>
</div>
<ul class="profile-dropdown onhover-show-div profilewidth">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li class="text-gray" (click)="openUserDetails()"><a><app-feather-icons [icon]="'user'"></app-feather-icons><span class="text-gray">User Profile</span></a></li>
  <li (click)="logout()"><a><app-feather-icons [icon]="'log-in'"></app-feather-icons><span class="text-gray">Log Out</span></a></li>
</ul>