import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() classes:string;
  @Input() buttonLable  : any;
  @Input() imgUrl :any;
  @Input() buttonImageUrl:string;
  @Input() button_2:boolean =false;
  @Input() disabledValue:any;
  @Input() buttonType:string;
  @Input() buttonClass:string;
  @Input() spanClass:string;
  @Output("buttonOutputEvent") buttonOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() inputReadonly : boolean  = false;
  constructor() { }


  onButtonClick(value: string) 
  {
    this.buttonOutputEvent.emit(value);
  }


  ngOnInit(): void {
  }

}
