import { BehaviorSubject, Subject } from 'rxjs';
import { Menu } from './../../services/nav.service';
import { Injectable } from '@angular/core';
import { StorageService } from '../../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  allOpenedTabs = []
  tabs: Menu[] = [];
  addedTabs = new BehaviorSubject<Menu[]>([])
  currentTab = new BehaviorSubject<string>('')
  tabName = new BehaviorSubject<string>('')
  thisTab = ''
  constructor(
     public storageService:StorageService,  
      //  public agGridService: AgGridService,
  ) { }

  addTab(item) {
    let cleanName = ''
    console.log('the item is ', item)
    console.log('the item is ', typeof(item))
   // this.agGridService.agGridPaginationConfig.pageSize = 25;
    if(typeof(item) == 'string'){
      cleanName = item?.substring(item.lastIndexOf('/') + 1);
    }
    if(typeof(item) == 'object'){
      cleanName = item?.path?.substring(item?.path.lastIndexOf('/') + 1);
    this.tabName.next(item.title)

    }
    console.log('the clean Name is ', typeof(cleanName))
    this.currentTab.next(cleanName)
    this.tabs = this.storageService.retrieve("allTabs");
    var tabDetails = this.tabs?.find(a=>a.path == item.path );
    if(tabDetails == undefined && item?.title !=  undefined)
    {
      this.tabs?.push(item);
      this.storageService.store("allTabs",this.tabs);
      this.addedTabs.next(this.tabs);
    }
    
  }
   
  getTabItem(item: Menu) {
    return item
  }
   
  deleteTab(index: number) {
    this.tabs = this.storageService.retrieve("allTabs");
    this.tabs.splice(index, 1);
    this.storageService.store("allTabs",this.tabs);
    this.addedTabs.next(this.tabs);
  }

}
