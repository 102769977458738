import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
@Input() inputSerachValue;
@Input() inputSearchPlaceholder:string='';
@Output() inputSerachValueEmit:EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clearSearch(){
    if(this.inputSerachValue === ''){
      this.inputSerachValueEmit.emit(this.inputSerachValue);
    }
  }

}
