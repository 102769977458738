import { GridName } from "src/app/components/list-manager/list-manager.model";


export class AgGridI {
  colDefs: AgColDefsI[];
  rowSelection: "single" | "multiple";
  storageName?: GridName;
}
export class AgColDefsI {
  resizable?: boolean;
  headerName?: string;
  headerClass?: string;
  field: string;
  suppressMovable?: boolean;
  lockPosition?: boolean | "left" | "right" | null;
  lockPinned?: boolean;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  editable?: any;
  sortable?: boolean | false;
  isfilter?: boolean | false; // to display filter icon in header
  pinned?: boolean | "left" | "right" | null;
  headerCheckboxSelection?: boolean | false | any;
  checkboxSelection?: boolean | false | any;
  cellClass?: string;
  hide?: boolean;
  cellClassRules?: any;
  cellStyle?: any;
  cellRenderer?: any;
  cellRendererParams?: any;
  cellEditor?: any;
  headerComponentFramework?: any;
  suppressMenu?: boolean;
  cellRendererFramework?: any;
  headerComponentParams?: any;
  tooltipField?: any;
  flex?: number;
  uniqueName?: string = "";
}
export class ManagerListStatusModel {
  mailingListId: string = "";
  isDeleted:boolean = true;
  isActive:boolean;
  action: string = "Status";
}
export class MailingListIds {
  mailingListIds: any[];

  constructor(mailingListIds: number[]) {
    this.mailingListIds = mailingListIds;
  }
}

export class PaginationBaseModel {
  sortColumn: string = "";
  sortDirection: string = "";
  pageNumber: number = 1;
  pageSize: number = 25;
  searchText: string = "";
  getAll: boolean = false;
}
export class TablePaginationModel extends PaginationBaseModel{
  id: string;
}
export class AgGridPaginationConfig {
  rows: any[] = [];
  totalRecords: number = 0;
  pageSize: number = 25;
  getAll: boolean = false;
  searchText: string = "";
  currentPageNumber: number = 1;
  tableOffset: number = 0;

  limitOptions = [
    {
      key: "10",
      value: 10,
    },
    {
      key: "25",
      value: 25,
    },
    {
      key: "50",
      value: 50,
    },
    {
      key: "75",
      value: 75,
    },
    {
      key: "100",
      value: 100,
    },
    {
      key: "All",
      value: 1,
    },
  ];
}
