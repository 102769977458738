import {Injectable } from '@angular/core';
import {DataService} from '../../shared/services/data.service';
import {StorageService} from '../../shared/services/storage.service'
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import { PaginationBaseModel, TablePaginationModel, ManagerListStatusModel, MailingListIds } from 'src/app/shared/components/ag-grid/ag-grid.model';

@Injectable({
    providedIn: 'root'
  })
  export class ListManagerService {
    public id:string='';
    public statusUpdate = new BehaviorSubject(undefined);

    
    constructor(public service:DataService) { }



     getAllManagerList(getAllMailingList:PaginationBaseModel){
      let url = "smxEmailPlatform/MailingList/getAllMailingList";
      return this.service.post(url, getAllMailingList).pipe<Result>(tap((response: any) => {
      return response;
      })
      );
    }
    getAllManagerListDetails(getAllMailingListDetails:TablePaginationModel){
      let url = "smxEmailPlatform/MailingList/getAllMailingListDetails";
      return this.service.post(url, getAllMailingListDetails).pipe<Result>(tap((response: any) => {
      return response;
      })
      );
    }

    managerListStatusUpdate(statusUpdateData:ManagerListStatusModel){
      let url = "smxEmailPlatform/MailingList/StatusUpdate";
      return this.service.post(url, statusUpdateData).pipe<Result>(tap((response: any) => {
      return response;
      })
      );
    }
    deleteManagerList(id: MailingListIds ): Observable<Result> {
      let url = "smxEmailPlatform/MailingList/deleteMailingList";
      return this.service.post(url, id).pipe<Result>( tap((response: any) => {
          return response;
        })
      );
    }
    deletemanagerListDetails(id: any ): Observable<Result> {
      let url = "smxEmailPlatform/MailingList/deleteListDetails";
      return this.service.post(url, id).pipe<Result>( tap((response: any) => {
          return response;
        })
      );
    }


  }
