import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Path } from "src/app/shared/model/enum";
import { TabsService } from "../../tabs/tabs.service";
import { Router } from "@angular/router";
import { Menu } from "src/app/shared/services/nav.service";
import {ListManagerService} from '../../../../components/list-manager/list-manager.service'
import { AgGridPaginationConfig, PaginationBaseModel, TablePaginationModel } from "../ag-grid.model";
import { AgGridService } from "../ag-grid.service";
@Component({
  selector: "app-mailing-list-renderer",
  templateUrl: "./mailing-list-renderer.component.html",
  styleUrls: ["./mailing-list-renderer.component.scss"],
})
export class MailingListRendererComponent implements OnInit, ICellRendererAngularComp
{
  dataToShow = "";
  params: any;
  enumPath = Path;
  paginationBaseModel = new PaginationBaseModel();
  tablePaginationModel = new TablePaginationModel();
  agGridPaginationConfig = new AgGridPaginationConfig();

  constructor(private tabsService: TabsService, private router: Router,
    public listManagerService: ListManagerService,
    public agGridService: AgGridService,
  ) {}
  agInit(params: ICellRendererParams<any, any>): void {
    this.dataToShow = params.value;
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  ngOnInit(): void {}
  changeRoute(params) {
    if(params.data.noOfRecords > 0){
    this.listManagerService.id=params.data.id;
    let id = params.value
    let url = `list-manager/list-manager-info/${this.listManagerService.id}`
    const item: Menu = { path: url, icon: '', title: params.value, type: 'link',id:params.data.id}
    this.tabsService.addTab(item);
    this.router.navigateByUrl(url);
    this.initDatatable()
    }
  }
  initDatatable() {
    this.tablePaginationModel={
      sortColumn: "",
      sortDirection: "",
      pageNumber: this.agGridPaginationConfig.currentPageNumber,
      pageSize: this.agGridPaginationConfig.pageSize,
      searchText: this.agGridPaginationConfig.searchText,
      getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
      id:this.listManagerService.id,
  }
    this.agGridService.tablePaginationData.next(this.tablePaginationModel);
  }

}
