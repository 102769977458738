import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridService } from 'src/app/shared/components/ag-grid/ag-grid.service';
import { Button, PopUpTitles } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-common-model-pop-up',
  templateUrl: './common-model-pop-up.component.html',
  styleUrls: ['./common-model-pop-up.component.scss']
})
export class CommonModelPopUpComponent implements OnInit {


  @Input() fullName: string;
  @Input() title: string;
  @Input() pageType:string;
  @Input() pagelistType:string;
  @Input() ResMsgFromMailingList:string;
  @Input() message: string;
  @Input() templateName: string;
  @Input() deleteForAdmin:boolean=false;

  
  @Output() delPopupforAdmin: EventEmitter<any> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal, public agGridService: AgGridService) { }
  enumPopUpTitles = PopUpTitles;
  enumButton = Button;
  closedPopup(){
    this.agGridService.unChecked.next(false);
  }

  deletePopup(){
    this.delPopupforAdmin.emit(true);
    setTimeout(() => {
    this.deleteForAdmin=true;
    }, 1000);
    
  }

  ngOnInit(): void {
  }

}
