import { Routes } from "@angular/router";

export const content: Routes = [
    {
    path: "dashboard",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },

  {
    path: "list-manager",
    loadChildren: () =>
      import("../../components/list-manager/list-manager.module").then(
        (m) => m.ListManagerModule
      ),
  },

  {
    path: "campaign-manager",
    loadChildren: () =>
      import("../../components/campaign-manager/campaign-manager.module").then(
        (m) => m.CampaignManagerModule
      ),
  },

  {
    path: "reports",
    loadChildren: () =>
      import("../../components/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
  },

  {
    path: "settings",
    loadChildren: () =>
      import("../../components/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },

  // {
  //   path: "simple-page",
  //   loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  // },
  // {
  //   path: "single-page",
  //   loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  // },
];
