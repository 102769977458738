import { Component, OnInit, Inject, ChangeDetectorRef, ElementRef, ViewChild, AfterViewChecked } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Menu, NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {TabsService} from "../tabs/tabs.service"
import { StorageService } from "../../services/storage.service";
import { CommonService } from "../../services/common.service";
SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit ,AfterViewChecked{
  imageData: string ='../../../../assets/SVGIcons/logo.svg';
  widthVal:number;
  public menuItems: Menu[];
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = true;
  activeIcon: string;
  showlist: boolean = false;
  currentNav: string;
  clickValue:boolean= true;
  @ViewChild("header") header: ElementRef;

  public elem: any;

  constructor(public layout: LayoutService, public navServices: NavService,
    public storageService:StorageService,  
     @Inject(DOCUMENT) private document: any,
     private router: Router,
     private cdref: ChangeDetectorRef,
     public tabsService: TabsService,
     private modalService: NgbModal,
     public commonService: CommonService,
    ) {
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // let cleanName = event.url.replace('/crm/', '')
          let cleanName = event.url.substring(event.url.lastIndexOf('/') + 1);
          cleanName = cleanName.charAt(0).toUpperCase() + cleanName.slice(1);
          this.tabsService.thisTab = cleanName
          if(cleanName == 'Customer'){
            this.tabsService.thisTab = 'Customers'
          }
          menuItems.filter((items) => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) {
              return false;
            }
            items.children.filter((subItems) => {
              if (subItems.path === event.url.substring(1)) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) {
                return false;
              }
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });

  }
  ngAfterViewChecked() {
    this.commonService.headerHeight.next(this.header.nativeElement.offsetHeight);
  }
  ngOnInit() {
    this.elem = document.documentElement;
    if (this.width < 1025) {
      this.leftArrowNone = false;
      this.rightArrowNone = false;
    }
    else {
      this.leftArrowNone = true;
      this.rightArrowNone = true;
    }


  }

  scrollLeft(){}
  scrollRight(){}
  addListShow(val) {
  }
  addTabs(item){
    this.tabsService.addTab(item);
  }
  toggelNotificationSideBar(val,clickValue) {}
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

}
