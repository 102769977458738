import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
})
export class SwitchComponent implements OnInit {
  @Output() switchValueEmit: EventEmitter<any> = new EventEmitter();

  @Input() status: boolean;
  @Input() isEditable?: boolean;
  @Input() id: boolean;
  ngOnInit(): void {}
}
