<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type"
     [class]="layout.config.settings.layout_version">
  <div class="page-wrapper" 
       id="canvas-bookmark"
       [ngClass]="layoutClass">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
        <div class="page-body" [style]="flexibleHeight">
            <app-tabs class="d-block h-100"></app-tabs>
        </div>
    </div>
    <!-- Page Body End-->
    <!-- footer start-->
    <!-- {{storageservice.retrieve('pinnedValues').length == 0?'d-none':0}} -->
    <!-- <footer #footer class="footer position-fixed d-flex align-items-center py-2 h-3rem">
      <app-footer></app-footer>
    </footer> -->
    <!-- footer End-->
  </div>
</div>
<!-- page-wrapper End-->