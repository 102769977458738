<div class="d-flex justify-content-evenly mt-2" *ngIf="params.delete == 'delete' && params.edit == 'edit' ">
  <app-svg-icon
   [src]="'assets/SVGIcons/editicon.svg'"
   class="d-flex align-items-center hand"
  ></app-svg-icon>
  <!-- (click)="openEditModal(params)" -->

  <app-svg-icon
   [src]="'assets/SVGIcons/deleteicon.svg'"
   class="d-flex align-items-center hand"
   (click)="openDeleteModal(params)"
   ></app-svg-icon>
</div>
