import { Component, OnInit } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { IHeaderParams } from "ag-grid-community";
import { DisplayName } from "src/app/shared/model/enum";

@Component({
  selector: "app-ag-custom-header",
  templateUrl: "./ag-custom-header.component.html",
  styleUrls: ["./ag-custom-header.component.scss"],
})
export class AgCustomHeaderComponent implements OnInit, IHeaderAngularComp {
  public params!: IHeaderParams;
  public ascSort = "inactive";
  public descSort = "inactive";
  public noSort = "inactive";
  enumDisplayName = DisplayName;

  constructor() {}
  agInit(params: IHeaderParams): void {
    this.params = params;
    var aa = params.columnApi.getValueColumns;
    params.column.addEventListener(
      "sortChanged",
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = "inactive";
    if (this.params.column.isSortAscending()) {
      this.ascSort = "active";
    } else if (this.params.column.isSortDescending()) {
      this.descSort = "active";
    } else {
      this.noSort = "active";
    }
    this.params.api.resetRowHeights();
  }

  onSortRequested(order: "asc" | "desc" | null, event: any) {
    this.params.setSort(order, event.shiftKey);
    this.params.api.resetRowHeights();
  }

  refresh(params: IHeaderParams) {
    return false;
  }

  ngOnInit(): void {}
}
