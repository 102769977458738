<div>
    <div class="modal-header border-0 justify-content-betweeen">
      <h6 class="modal-title text-primary-color text-center font-15">Edit Mailing List</h6>
    </div>
    <div class="modal-body pt-3">
      <div class="text-center pb-1">
        <app-input
        [inputLabel]="'Mailing List Name'"
        [inputType]="'text'"
        [inputReqValidation]="true"
        >
        </app-input>
      </div>
    </div>
    <div class="modal-footer border-0 justify-content-end">
      <div class="m-0">
       <app-button 
         [buttonType]="'button'"
         [button_2]="true"
         (click)="activeModal.close()"
         [buttonClass]="'button button-info me-4'"
         [buttonLable]="'Cancel'"
         >
         </app-button>
         <app-button 
         [buttonType]="'submit'"
         [button_2]="true"
         (click)="addEditValue()"
         [buttonClass]="'button button-primary mx-0'"
         [buttonLable]="isAddValue == true ? 'Add' : 'Save'"
         ></app-button> 
      </div>
    </div>
  </div>
  
