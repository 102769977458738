import { Component, Input, OnInit } from "@angular/core";
import { AgGridService } from "../ag-grid/ag-grid.service";
import { StorageService } from "../../services/storage.service";
import {AgGridI,AgGridPaginationConfig,PaginationBaseModel,TablePaginationModel,} from "./ag-grid.model";
import { ColDef, ColumnApi, ColumnResizedEvent, DragStoppedEvent, GridApi, RowClassRules, RowHeightParams, SelectionChangedEvent } from "ag-grid-community";
import { Subscription, catchError } from "rxjs";
import { AgCustomHeaderComponent } from "./ag-custom-header/ag-custom-header.component";
import {ListManagerService} from "../../../components/list-manager/list-manager.service";
import { Router } from "@angular/router";
import { TabsService } from "../tabs/tabs.service";
import { Result } from "../../model/response.model";
declare var window: any;

@Component({
  selector: "app-ag-grid",
  templateUrl: "./ag-grid.component.html",
  styleUrls: ["./ag-grid.component.scss"],
})
export class AgGridComponent implements OnInit {
  @Input() dataModel: AgGridI;
  @Input() getRowStyle: any;
  agGridPaginationConfig = new AgGridPaginationConfig();
  isLoading: boolean = false;
  subscription: Subscription;
  deviceWidth: any;
  paginationBaseModel = new PaginationBaseModel();
  tablePaginationModel = new TablePaginationModel();
  currentTab: string;


  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
  };
  public components: {
    [p: string]: any;
  } = {
    agColumnHeader: AgCustomHeaderComponent,
  };
  overlayNoRowsTemplate =
    '<div class="ag-overlay-no-rows-center">No Records Available</div>';

  constructor(
    public agGridService: AgGridService,
    public storageService: StorageService,
    public listManagerService :ListManagerService,
    public route: Router,
    public tabService: TabsService,
  ) {}

  ngOnInit(): void {
    this.subscription =  this.tabService.currentTab.subscribe(res => {
      if (res) {this.currentTab = res}
    })

    this.subscription =  this.agGridService.agGridPaginationConfigRes.subscribe((result) => {
      if (result) {this.agGridPaginationConfig = result;}
    });
    this.deviceWidth = window.innerWidth;
    console.log(this.route.url);
    this.loadData();
    this.mailingListInfo();
  }
  loadCurrentTab(){
    this.subscription =  this.tabService.currentTab.subscribe(res => {
      if (res) { this.currentTab = res }
    })
  }
  mailingListInfo(){
    this.subscription =   this.agGridService.tablePaginationData.subscribe(res => {
      if(res !== undefined){
      this.tablePaginationModel = res;
      if(this.route.url?.includes('list-manager/list-manager-info')){
        this.getAllManagerListDetails()
      }
    }else {
      if (this.route.url.includes('/list-manager/list-manager-info')){ 
      const id = this.route.url.split('/').pop();
      this.tablePaginationModel.id = id;
      this.getAllManagerListDetails()
    }
    }
    })
  }
  loadData(){
    this.subscription = this.agGridService.paginationData.subscribe(res => {
      if(res !== undefined){
      this.paginationBaseModel = res;
      if(this.route.url =='/list-manager' && this.currentTab == 'list-manager'){
        this.getAllManagerListData()
      }
    }
    })
  }
  getAllManagerListDetails(){
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;
    this.listManagerService.getAllManagerListDetails(this.tablePaginationModel).pipe(catchError((err) => this.handleError(err)))?.subscribe((data: Result) => {
        if (data.isSuccess === true) {
          this.agGridService.agGridPaginationConfigRes.next(this.agGridService.updateTableData(data));
          this.isLoading = false;
          this.agGridService.getGridApi()?.showNoRowsOverlay();
          this.agGridService.selectionChanged?.next(this.agGridService.getGridApi()?.getSelectedRows())
        }
      });
  }

  getAllManagerListData(){
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;
    this.listManagerService.getAllManagerList(this.paginationBaseModel).pipe(catchError((err) => this.handleError(err)))?.subscribe((data: Result) => {
        if (data.isSuccess === true) {
          this.agGridService.agGridPaginationConfigRes.next(this.agGridService.updateTableData(data));
          this.isLoading = false;
          this.agGridService.getGridApi()?.showNoRowsOverlay();
          this.agGridService.selectionChanged?.next(this.agGridService.getGridApi()?.getSelectedRows())
        }
      });
  }
  getRowHeight(params: RowHeightParams): number | undefined | null {
    if(params?.data?.mailingListId){return 45}
    else{return 32;}
  }
  public rowClassRules: RowClassRules = {};

  onGridReady(params) {
    this.agGridService.setGridApi(params.api);
    this.agGridService.setGridColumnApi(params.columnApi);
  }
  //#region onSelectionChanged
  onSelectionChanged(event: SelectionChangedEvent) {
    this.agGridService.selectionChanged.next(event);
  }
  onDragStopped(event: DragStoppedEvent) {
   // this.agGridService.onColumnDragStopped.next(event);
  }
  onGridSizeChanged($event) {}
  onFirstDataRendered(params: { api: GridApi; columnApi: ColumnApi }) {
    this.agGridService.saveColumnState(params.columnApi);
  }
  onRowClicked(params){
   console.log(params);
  }
  onColumnResized(event: ColumnResizedEvent) {
    if(event.source == 'uiColumnDragged'){
    window.colState = event.columnApi.getColumnState();
    this.storageService.store(this.dataModel.storageName, window.colState);
  }
  }

  columnMoved(event) {
    window.colState = event.columnApi.getColumnState(); 
    this.storageService.store(this.dataModel.storageName, window.colState);
  }

  onPageChanged(pageNum: number) {
    this.agGridService.onPageChanged(pageNum);
    this.initDatatable();
    if(this.route.url =='/list-manager' && this.currentTab == 'list-manager'){
      this.getAllManagerListData()
    }
  }
  onPageSizeChange(pageSize: number) {
    this.agGridService.onPageSizeChanged(pageSize);
    this.initDatatable();
    if(this.route.url =='/list-manager' && this.currentTab == 'list-manager'){
      this.getAllManagerListData()
    }
  }
  initDatatable(){
    this.paginationBaseModel = {
      sortColumn: "",
      sortDirection: "",
      pageNumber: this.agGridPaginationConfig.currentPageNumber,
      pageSize: this.agGridPaginationConfig.pageSize,
      searchText: this.agGridPaginationConfig.searchText,
      getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
    };
  }
  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }
  handleError(err: any): any {
    console.log(err);
  }

}
