export enum Path {
    USER_MANAGERLIST_PATH = "",
}  

export enum Button {
    CLONE = "Clone",
    DELETE = "Delete",
    EDIT = "Edit",
    CANCEL = "Cancel",
}

export enum DisplayName {
  MAILINGLIST = "Mailing List",
  ACTIVE = "Active",
  COMPANY = "Company",
}

export enum PopUpTitles {
  DELETE = "Delete",
}
export enum DeletePageType {
  MAILINGLIST = "mailing list",
  MAILINGLISTDETAILS = "record",

}


