<div class="container-fluid d-flex flex-column">
    <app-common-header></app-common-header>
    <app-ag-grid 
      class=" d-flex flex-column position-relative h-100"
      [dataModel]="agGridDataModelListManager"
    ></app-ag-grid>
  </div>




  
  