// import { Path } from './../../model/enum';
import { Component, HostListener, OnInit } from '@angular/core';
import {Path} from "../../../shared/model/enum"
import { NavigationEnd, Router } from '@angular/router';
import { TabsService } from './tabs.service';
import {StorageService} from "../../services/storage.service"
import { AgGridService } from '../ag-grid/ag-grid.service';
import { PaginationBaseModel } from '../ag-grid/ag-grid.model';
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  tabs = [];
  activeTabUrl;
  enumPath = Path;
  isSidebarNotificationShow: boolean;
  deviceWidth: any;
  paginationBaseModel = new PaginationBaseModel();

  constructor(private tabsService: TabsService,
    private router: Router,
    public storageService: StorageService,
    public agGridService: AgGridService,
  ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeTabUrl = event.urlAfterRedirects.substring(1);
        if (this.tabs?.length === 0) {
          this.tabsService.addTab(this.activeTabUrl);
        }
      }
    });

  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.deviceWidth = event.target.innerWidth;
  }
  @HostListener("window:beforeunload", ["$event"])
  onReload() {
    this.storageService.store("allTabs", this.tabs);
   // this.storageService.store("allTabs", []);
   // this.storageService.store("companyId", this.companiesServices.id);
  }
clearList(){
  const currentRoute = this.router.url;
  if (currentRoute === '/dashboard') {
      this.storageService.store("allTabs", []);
  } 
}
  ngOnInit(): void {
    this.clearList()
    this.tabsService.addedTabs.subscribe((res) => {
      this.tabs = this.storageService.retrieve("allTabs");
      if (this.tabs?.length == 0) {
        this.tabs = res;
      }
      this.tabs?.map((value) => {
        if (!this.tabsService.allOpenedTabs.includes(value.path + '/')) {
          this.tabsService.allOpenedTabs.push(value.path + '/');
        }
      });
    });
  }

  //  single tab close 
  handleError(err: any): any { console.log(err) }
  id: string;
  closeTab(index: number, event: Event) {  
    this.agGridService.onPageChanged(0)
    this.agGridService.agGridPaginationConfig.pageSize = 25
    if (index > 0) {
      if (this.activeTabUrl == this.tabs[index].path) {
        this.router.navigateByUrl(this.tabs[index - 1].path);
        this.id = this.tabs[index - 1].id;
        let cleanName = this.tabs[index - 1].path.substring(this.tabs[index - 1].path.lastIndexOf('/') + 1);
        this.tabsService.currentTab.next(cleanName);
        console.log('the clearn name in$$$$$ ', cleanName)
      }

    }
    if (this.tabs.length > 0) {
      this.tabsService.allOpenedTabs = this.tabsService.allOpenedTabs?.filter(
        (value) => value != this.tabs[index]?.path + '/'
      );
    }
    this.tabsService.deleteTab(index);
    const data=  this.tabs.find(a=>a.path===this.activeTabUrl)??'';
    if (index == 0) {
      this.tabsService.allOpenedTabs = []
      if (this.tabs.length == 1) {
        this.router.navigateByUrl(this.tabs[index].path);
      } 
      else if(data!=''){
        console.log("data.......",data)
      }
      else if (this.tabs.length > 1) {
        this.router.navigateByUrl(this.tabs[index].path);
      }
      else if (this.tabs.length == 0) {
        this.router.navigateByUrl('dashboard');
      }

    }
    event.preventDefault();
    if (this.id !== undefined) {
    }
  }

  onTabChange(event) {
    console.log('tab-changed ')
    this.activeTabUrl = event.nextId;
    this.agGridService.onPageChanged(0)
    this.agGridService.agGridPaginationConfig.pageSize = 25
    this.paginationBaseModel.searchText = ''
    this.router.navigateByUrl(event.nextId);
    let cleanName = event.nextId.substring(event.nextId.lastIndexOf('/') + 1);
    this.tabsService.currentTab.next(cleanName);
  }

  // All tabs close 
  closeAll(){
    this.tabs=[];
    this.tabsService.tabs = [];
    this.tabsService.allOpenedTabs = [];
    this.storageService.store("allTabs", []);
    this.router.navigateByUrl('dashboard');
  }
  toggleIsSidebarNotificationShow(){
      this.isSidebarNotificationShow = false  
  }

}
