import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";

// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { TabsComponent } from "./components/tabs/tabs.component";
import { ListManagerComponent } from "../components/list-manager/list-manager.component";
import { CampaignManagerComponent } from "../components/campaign-manager/campaign-manager.component";
import { ReportsComponent } from "../components/reports/reports.component";
import { SettingsComponent } from '../components/settings/settings.component';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import {MailingListRendererComponent} from "../shared/components/ag-grid/mailing-list-renderer/mailing-list-renderer.component"
import {AgCustomHeaderComponent} from "../shared/components/ag-grid/ag-custom-header/ag-custom-header.component";
import { AgSwitchComponent } from './components/ag-grid/ag-switch/ag-switch.component';
import { SwitchComponent } from './components/switch/switch.component';
import { InputComponent } from './components/input/input.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ButtonComponent } from './components/button/button.component'
import { CommonHeaderComponent } from "../components/common-header/common-header.component";
import { SvgRendererComponent } from './components/ag-grid/svg-renderer/svg-renderer.component';
import { CustomLoaderComponent } from './components/loader/custom-loader/custom-loader.component';
@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        ContentComponent,
        BreadcrumbComponent,
        FeatherIconsComponent,
        FullComponent,
        LoaderComponent,
        TapToTopComponent,
        SearchComponent,
        LanguagesComponent,
        NotificationComponent,
        BookmarkComponent,
        CartComponent,
        MessageBoxComponent,
        MyAccountComponent,
        SvgIconComponent,
        SwiperComponent,
        TabsComponent,
        ListManagerComponent,
        CampaignManagerComponent,
        ReportsComponent,
        SettingsComponent,
        AgGridComponent,
        MailingListRendererComponent,
        AgCustomHeaderComponent,
        AgSwitchComponent,
        SwitchComponent,
        InputComponent,
        SearchInputComponent,
        ButtonComponent,
        CommonHeaderComponent,
        SvgRendererComponent,
        CustomLoaderComponent
    ],
    providers: [NavService, LayoutService, DecimalPipe],
    exports: [
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        LoaderComponent,
        BreadcrumbComponent,
        FeatherIconsComponent,
        TapToTopComponent,
        SvgIconComponent,
        SwiperModule,
        ListManagerComponent,
        CampaignManagerComponent,
        ReportsComponent,
        SettingsComponent,
        AgGridComponent,
        MailingListRendererComponent,
        AgCustomHeaderComponent,
        CommonHeaderComponent,
        ButtonComponent,
        InputComponent,
        CustomLoaderComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SwiperModule,
        AgGridModule,
    ]
})
export class SharedModule {}
