import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import autoTable from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  pinnedIconsList=[]
  public isselecte = new Subject<boolean>();
  public headerHeight= new BehaviorSubject<number>(50);


  constructor() { }

  //#region dowonload pdf or print
 
  generatePdfAndPrint(data: any[], columns: any[], fileName: string): void {
    const doc = new jsPDF({orientation:"landscape"});
    const headData = [columns?.map(column => column?.title)];
    const bodyData = data?.map(row => columns?.map(column => {
      // Convert boolean values to "yes" or "no"
      //const value = row[column?.name];
      let value = row[column?.name];
      if (column?.name === 'leadScoreId') {
        value = row['leadScoreName'];
      }else  if (column?.name === 'intentToBuyId') {
        value = row['intentToBuyName'];
      }
    
    //  return value;
      return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
  }));
    autoTable(doc, {
      styles: { fontSize: 8},
      head: headData,
      body:bodyData,
      headStyles:{fontSize:9,valign:"middle"}
    })
        doc.save(`${fileName}.pdf`);
       // Get the data URI of the PDF
      const dataUri = doc.output("datauristring");
      // Open the PDF in a new window for printing
      const printWindow = window?.open();
      if (printWindow) {
        printWindow?.document?.write(`<iframe width="100%" height="100%" src="${dataUri}" download="${fileName}.pdf" title="${fileName}.pdf"></iframe>`);
        printWindow?.document?.close();
        // printWindow?.print(); // Open the print dialog
      } else {
        // Handle popup blocked or failed to open
        alert('Popup blocked! Please enable popups for this site and try again.');
      }
      // const printWindow = window?.open(dataUri, '_blank');
      // if (printWindow) {
      //   printWindow?.print(); // Open the print dialog
      // } else {
      //   // Handle popup blocked or failed to open
      //   alert('Popup blocked! Please enable popups for this site and try again.');
      // }
  
  }
  //#endregion dowonload pdf or print


  //#region export file xlsx
 
  export(header:any[],dataList:any[] ,fileName): void {
    const modifiedDataList = dataList.map(item => {
      return item.map(value => {
          return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
      });
  });
    /* generate worksheet */
    const data = [...header,...modifiedDataList]
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  
  //#endregion
  
}


