import { Component, ElementRef, Input, Renderer2, ViewChild } from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.scss"],
})
export class SvgIconComponent {
  @ViewChild('svgContainer', { static: true }) svgContainer: ElementRef;

  @Input() public src:any="";
  @Input() public classSvg:any;
  
  constructor(private renderer: Renderer2,private el: ElementRef,
    private http: HttpClient) { }

  ngOnInit(): void {
    if(this.src != undefined){
      this.http.get(this.src,{responseType: 'text'}).subscribe(svg => {
        if(this.src != ''){
          this.el.nativeElement.innerHTML = svg;
        }
    });
    }
  }
}
