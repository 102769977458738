<ng-container [ngSwitch]="title" >
      <!-- Delete PopUp start  -->
  <div *ngSwitchCase="enumPopUpTitles.DELETE">
    <div *ngIf="!deleteForAdmin">
    <div class="modal-header border-0 justify-content-center">
      <h6 class="modal-title text-center text-danger-color font-15 mt-2">{{title}}</h6>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <p  class="m-0 text-light-gray font-12">You are about to delete a {{pageType}}.</p>
        <p class="m-0 text-light-gray font-12">Are you sure you want to delete
          <span class="as-active text-break text-primary-color font-12 my-auto">{{fullName | titlecase}}</span><br /> 
          from the {{pagelistType}}?
        </p>
      </div>
    </div>
    <div class="modal-footer border-0 justify-content-center">
      <div class="m-0">
        <app-button [buttonType]="'button'" class="me-3"  [button_2]="true" [buttonClass]="'button button-info px-2 font-11'" (click)="activeModal.close({title,confirm:false})" [buttonLable]="'Cancel'"></app-button>
        <app-button 
         [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-danger px-2 font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="'Delete'"></app-button>
      </div>
    </div>
  </div>
    <div div *ngIf="deleteForAdmin">
      <div class="modal-header border-0 justify-content-center">
        <h6 class="modal-title text-center text-danger-color font-15 mt-2">{{title}}</h6>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <p class="m-0 text-light-gray font-12 text-truncate my-auto">{{ResMsgFromMailingList}}</p>
        </div>
      </div>
      <div class="modal-footer border-0 justify-content-center">
        <div class="m-0">
          <app-button [buttonType]="'button'" class=""  [button_2]="true" [buttonClass]="'button button-info px-2 font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="'Okay'"></app-button>
        </div>
      </div>
    </div>

</div>
 <!-- Delete PopUp end  -->

</ng-container>


