import { Component, OnInit } from '@angular/core';
import { AgGridI } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { AgGridDataModelListManager } from './list-manager.model';

@Component({
  selector: 'app-list-manager',
  templateUrl: './list-manager.component.html',
  styleUrls: ['./list-manager.component.scss']
})
export class ListManagerComponent implements OnInit {
  agGridDataModelListManager: AgGridI;

  constructor() { }

  ngOnInit(): void {
    this.agGridDataModelListManager = AgGridDataModelListManager;

  }

}
