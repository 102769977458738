import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from "rxjs";
import { AgGridPaginationConfig, PaginationBaseModel, TablePaginationModel} from "./ag-grid.model";
import { Result } from "../../model/response.model";
import { StorageService } from "../../services/storage.service";
import { ColumnApi } from "ag-grid-community";

@Injectable({
  providedIn: 'root'
})
export class AgGridService {
  public gridColumnApi = new BehaviorSubject(undefined);
  public gridApi = new BehaviorSubject(undefined);
  public agGridPaginationConfig = new AgGridPaginationConfig();
  public agGridPaginationConfigRes = new BehaviorSubject(undefined);
  tablePaginationData = new BehaviorSubject<TablePaginationModel>(undefined);
  paginationData = new BehaviorSubject<PaginationBaseModel>(undefined);
  unChecked = new BehaviorSubject(undefined);
  selectionChanged = new Subject();
  constructor( public storageService:StorageService) {}

  getGridApi() {
    return this.gridApi.getValue();
  }

  setGridApi(gridApi) {
    this.gridApi.next(gridApi);
  }

  getGridColumnApi() {
    return this.gridColumnApi.getValue();
  }

  setGridColumnApi(gridColumnApi) {
    this.gridColumnApi.next(gridColumnApi);
  }

  public onPageChanged(pageNum: number) {
    this.agGridPaginationConfig.currentPageNumber = pageNum;
    this.agGridPaginationConfig.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageSize: number) {
    this.agGridPaginationConfig.pageSize = pageSize;
    this.onPageChanged(1);
  }

  public onSort(pageNum: number) {
    this.onPageChanged(1);
  }

  public updateTableData(res: any): any {
    this.agGridPaginationConfigRes.next(undefined);
   // this.agGridPaginationConfig.rows = res?.response;
    this.agGridPaginationConfig.rows = res?.response ? res.response : [];
    this.agGridPaginationConfig.totalRecords = res?.totalRecords;
    return this.agGridPaginationConfig;
  }
  private columnState: any;

  saveColumnState(columnApi: ColumnApi) {
    this.columnState = columnApi.getColumnState();
  }

  restoreColumnState(columnApi: ColumnApi) {
    if (this.columnState) {
      columnApi.setColumnState(this.columnState);
    }
  }


}
