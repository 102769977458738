import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ListManagerService } from "src/app/components/list-manager/list-manager.service";
import { ManagerListStatusModel } from "../ag-grid.model";

@Component({
  selector: "app-ag-switch",
  templateUrl: "./ag-switch.component.html",
  styleUrls: ["./ag-switch.component.scss"],
})
export class AgSwitchComponent implements OnInit, ICellRendererAngularComp {
  public cellValue!: string;
  checked = true;
  params: any;
  managerListStatusUpdate = new ManagerListStatusModel();

  constructor(public toaster: ToastrService,
    public listManagerService :ListManagerService,
  ) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return false;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  ngOnInit(): void {}
  checkedHandler(event) {
    this.statusUpdate(event);
  }
  statusUpdate(value){
    this.managerListStatusUpdate.mailingListId = this.params.data.id;
    this.managerListStatusUpdate.isActive = value;
    this.listManagerService.managerListStatusUpdate(this.managerListStatusUpdate).subscribe((res)=>{
      if(res.isSuccess){
        this.toaster.success(res.message);
         this.listManagerService.statusUpdate.next(true);
      }else {
        this.toaster.error(res.message)
      }
    })

  }

}
